exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-feest-js": () => import("./../../../src/pages/feest.js" /* webpackChunkName: "component---src-pages-feest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-op-het-veld-js": () => import("./../../../src/pages/op-het-veld.js" /* webpackChunkName: "component---src-pages-op-het-veld-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-verkoop-js": () => import("./../../../src/pages/verkoop.js" /* webpackChunkName: "component---src-pages-verkoop-js" */),
  "component---src-pages-winkelwagen-js": () => import("./../../../src/pages/winkelwagen.js" /* webpackChunkName: "component---src-pages-winkelwagen-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

